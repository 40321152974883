<template>
	<nav class="nav">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10414')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<Loading v-if="sendPay03" />
	<div id="container" class="container container--fit">
		<div id="sub" class="sub booking">
			<div class="box">
				<!-- 예약 숙소 -->
				<section class="booking-top">
					<div class="box box--reverse">
						<h2 class="booking-top__title booking-top__title--small">{{t('10448')}}</h2>
						<p class="booking-top__price">
							<span>{{orderRooms.length}}건</span>
							<span>
							<strong class="red">{{totalPrice.toLocaleString("en-US")}}</strong>{{t('10449')}}
						</span>
						</p>
					</div>
				</section>
				<section class="content">
					<!-- 숙소 리스트 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<!-- 예약 숙소 1 -->
							<div class="booking-item" v-for="(order) in orderRooms" :key="order.id">
								<div class="booking-item__badge">{{t('10087')}}</div>
								<h3 class="booking-item__title">{{order.room.hotel_nm}}</h3>
								<p class="booking-top__desc">
									<span class="booking-top__badge">{{order.isDayuse ? '대실' : '숙박'}}</span>
									{{order.room.prod_nm}}
								</p>
								<div class="booking-item__date">
									{{order.checkin}} ~ {{order.checkout}}
								</div>
								<div class="booking-item__price">
									<strong>{{(order.price - order.dcPrice) > 0 ? (order.price - order.dcPrice).toLocaleString("en-US"): 0}}</strong>{{t('10449')}}
								</div>
								<div class="booking-item__select">
									<button type="button" class="btn-select" :class="{'btn-select--disabled': !order.isCoupon && isLogin, 'btn-select--active' : order.couponsLength > 0}" @click="handleOpenCoupon(order.hotelNo, order.id)">
										<span class="btn-select__wrap">
											<span class="btn-select__title">
												{{ !isLogin ? "로그인 후 할인 혜택 받으세요!" : order.isCoupon ? (order.couponsLength > 0 ? "적용된 쿠폰 " : "적용할 쿠폰을 선택하세요") : "적용가능한 쿠폰이 없어요"}}
											</span>
											<template v-if="order.couponsLength > 0">
												<span class="btn-select__number">
													{{order.couponsLength}}장
												</span>
												<span class="btn-select__price">
													-{{order.dcPrice.toLocaleString("en-US")}}{{t('10449')}}
												</span>
											</template>
										</span>
										<i class="icon icon-right--light"></i>
									</button>
								</div>
							</div>
						</div>
					</article>
					<!-- 예약자 정보 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<h3 class="booking-box__title"> {{t('10090')}} </h3>
							<div class="frms__item">
								<h4 class="frms__subtitle">
									{{t('10091')}} <span class="dot dot--red"></span>
								</h4>
								<div class="frms__wrap">
									<input v-model="name" type="text" class="ipt-frm" :placeholder="t('10835')" >
								</div>
							</div>
							<div class="frms__item">
								<h4 class="frms__subtitle">
									{{t('10092')}} <span class="dot dot--red"></span>
								</h4>
								<div class="frms__wrap" style="line-height:34px" v-if="isLogin">
									{{isVerified ? phoneNumber : checkPhoneNumber(userData.hp_no)[0]}}
									<div class="frms__btns">
										<button type="button" class="btn-link" @click="onChangeVerify">
											{{t('10492')}}
										</button>
									</div>
								</div>
								<div class="frms__wrap" v-if="!isLogin || changeVerify">
									<input type="tel" class="ipt-frm" v-model="phoneNumber" @keyup="checkNumber" maxlength="13" :placeholder="t('10827')"  :disabled="isSended">
									<div class="frms__btns">
										<button type="button" class="btn-frmreset" v-show="phoneNumber.length > 0" @click="reset" >
											<i class="icon icon-closesmall">{{t('10036')}}</i>
										</button>
										<button type="button" class="btn btn-medium btn-secondary" @click="onClickSendPhoneNumber" v-if="!isSended" :class="{active: isPhoneNumber && (!isSended || phoneValidateTime === 0)}" 
											:disabled="!(isPhoneNumber && (!isSended || phoneValidateTime === 0))">인증하기</button><!--재번역 체크-->
									</div>
								</div>
								<!-- <div class="frms__wrap" v-else>
									<input type="tel" class="ipt-frm" :placeholder="t('10827')" >
									<div class="frms__btns">
										<button type="button" class="btn-link">
											인증하기
										</button>
									</div>
								</div> -->
							</div>
							<div class="frms__item" v-if="isSended && !isVerified">
								<h3 class="frms__title">{{t('10124')}}</h3>
								<div class="frms__wrap" :class="{failed: isFailed}">
									<input type="text" pattern="[0-9]*" inputmode="numeric" class="ipt-frm" :placeholder="t('10779')" :value="verifyCode" @input="event => verifyCode = event.target.value">
									<div class="frms__btns">
										<button type="button" class="btn btn-medium btn-secondary active" @click="verify">
											{{t('10143')}}
										</button>
									</div>
								</div>
								<div class="frms__msg" v-if="isFailed">
									{{t('10126')}}
								</div>
								<div class="frms__wrap">
									<button type="button" class="btn-resend" @click="reSend">
											{{t('10125')}}
											<span class="frms__time eng">
												{{String(Math.trunc(phoneValidateTime / 60)).padStart(2, "0")}}:{{String(phoneValidateTime % 60).padStart(2, "0")}}
											</span>
									</button>
								</div>
							</div>
						</div>
					</article>
					<!-- 이용자 정보 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<h3 class="booking-box__title">{{t('10453')}}</h3>
							<p class="description">
								{{t('10454')}}
							</p>
							<div class="checkbox" style="margin:10px 0 25px">
								<label>
									<input type="checkbox" class="chk blind sameGuest" @click="checkname" v-model="sameGuest">
									<span class="checkbox__bg"></span>
									{{t('10455')}}
								</label>
							</div>
							<div class="frms__item">
								<h4 class="frms__subtitle">
									{{t('10035')}} <span class="dot dot--red"></span>
								</h4>
								<div class="frms__wrap" style="line-height:34px" v-if="sameGuest">
									{{name !== '' ? name : '예약자명을 입력해주세요.'}}
								</div>
								<div class="frms__wrap" v-else>
									<input type="text" class="ipt-frm" :placeholder="t('10835')"  v-model="guestName">
								</div>
							</div>
							<div class="frms__item">
								<h4 class="frms__subtitle">
									{{t('10037')}} <span class="dot dot--red"></span>
								</h4>
								<div class="frms__wrap" style="line-height:34px" v-if="sameGuest">
									{{isVerified ? phoneNumber : (isLogin ? checkPhoneNumber(userData.hp_no)[0] : '')}}
								</div>
								<div class="frms__wrap" v-else>
									<input type="tel" class="ipt-frm" :placeholder="t('10778')" v-model="guestPhoneNumber" @keyup="guestCheckNumber" maxlength="13" >
								</div>
							</div>
							<p class="description grey1" style="padding-top:10px" v-html="t('10493')">
							</p>
						</div>
					</article>
					<!-- 숙소 방문 수단 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<div class="flex">
								<h3 class="booking-box__title">{{t('10456')}} <span class="dot dot--red"></span></h3>
								<button v-if="ways.length !== 0" type="button" class="btn-link" @click="handleOpenWay">선택하기</button><!--재번역 체크-->
							</div>
							
							<ul class="booking-visit">
								<li v-if="ways.length === 0">
								<button type="button" class="btn btn-confirm" @click="handleOpenWay">선택하기</button><!--재번역 체크-->
								</li>
								<li v-else v-for="(way, index) in ways" :key="index">

									<div class="booking-visit__wrap" v-if="way.unavailed === false">
										{{way.hotel_nm === undefined ? orderRooms.find(orderRoom => orderRoom.hotelNo === way.hotel_no)?.room.hotel_nm ?? "" : way.hotel_nm}}
										<span :class="{red: way.unavailed}">{{way.unavailed === true ? t('10463') : way.visit_mean.slice(0,2)}}</span>
									</div>
									<template v-else>
										{{way.hotel_nm === undefined ? orderRooms.find(orderRoom => orderRoom.hotelNo === way.hotel_no)?.room.hotel_nm ?? "" : way.hotel_nm}}
										<span :class="{red: way.unavailed}">{{way.unavailed === true ? t('10463') : way.visit_mean}}</span>
									</template>
								</li>
							</ul>
						</div>
					</article>
					<!-- 금액 및 할인 정보 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<h3 class="booking-box__title">{{t('10464')}}</h3>
							<!-- 예약 금액 -->
							<ul class="info">
								<li>
									<h4 class="info__title">
										{{t('10097')}}
									</h4>
									<div class="info__wrap">
										<div class="info__text">
											<strong>{{totalPrice.toLocaleString("en-US")}}</strong>원
										</div>
									</div>
								</li>
							</ul>
							<template v-if="isLogin">
								<!-- 쿠폰/포인트 적용 -->
								<ul class="info info--line">
									<!-- [D] 쿠폰 미적용시 -->
									<li>
										<h4 class="info__title">
											쿠폰
										</h4>
										<div class="info__wrap">
											<div class="info__text" v-if="Object.keys(selectedCouponByOrderId).length === 0">
												총 0원 할인
												<i class="icon icon-right--light"></i>
											</div>
											<div class="info__text" v-else @click="handleOpenCouponStatus">
												<span class="skyblue">총 <strong>{{orderRooms.reduce((acc, cur) => acc + cur.dcPrice, 0).toLocaleString("en-US")}}</strong>원 할인</span>
												<i class="icon icon-right--light"></i>
											</div>
										</div>
									</li>
									<li>
										<h4 class="info__title">
											포인트
										</h4>
										<div class="info__wrap">
											<div class="info__text">
												{{totalPoint.toLocaleString("en-US")}}P 보유
											</div>
										</div>
									</li>
									<li>
										
										<div class="point-reset">
											<input type="text" pattern="[0-9]*" inputmode="numeric" class="ipt-point" :value="usingPoint > leftPrice ? leftPrice : usingPoint" @input="event => usingPoint = event.target.value" @keyup="pointInput" >
											<div class="frms__btns">
												<button type="button" class="btn-authtel" v-if="usingPoint !== '0'" @click="resetPoint">
													<i class="icon icon-closesmall">폼 리셋</i>
												</button>
											</div>
										</div>
										<button type="button" class="btn btn-third btn-medium" :class="{active: totalPoint > 0 && lastPrice !== 0}" :disabled="totalPoint === 0 || lastPrice === 0" @click="handleMaxPoint">전액사용</button>
									</li>
								</ul>
								<!-- 호텔샐러드만의 특별 할인 혜택 -->
								<div class="booking-special" v-if="isLogin & urlhost !== 'www.hellostay.co.kr'">
									<h3 class="booking-special__title">
										{{t('10497')}}
									</h3>
									<p class="description">
										{{t('10498')}}
									</p>
									<div class="booking-special__wrap">
										<ul class="info">
											<!-- [D] 제휴 할인 전 -->
											<li v-if="userData.mmaa_yn !== 'S' || userData.mmaa_yn !== 'O'">
												<h4 class="info__title">
													제휴할인
												</h4>
												<div class="info__wrap">
													<div class="info__text" @click="handleOpenArmy">
														<span class="grey1">인증하기</span>
														<i class="icon icon-right--light"></i>
													</div>
												</div>
											</li>
											<!-- [D] 제휴 할인 후 -->
											<li v-else>
												<h4 class="info__title">
													제휴할인
												</h4>
												<div class="info__wrap">
													<div class="info__text">
														{{t('10499')}}
														<i class="icon icon-right--light"></i>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
								<!-- [D] 할인 내역이 있을 경우 표시 -->
								<ul class="info info--line">
									<li v-if="Object.keys(selectedCouponByOrderId).length > 0">
										<h4 class="info__title">
											쿠폰
										</h4>
										<div class="info__wrap">
											<div class="info__text">
												-{{orderRooms.reduce((acc, cur) => acc + cur.dcPrice, 0).toLocaleString("en-US")}}원
											</div>
										</div>
									</li>
									<li v-if="usingPoint !== '0'">
										<h4 class="info__title">
											포인트
										</h4>
										<div class="info__wrap">
											<div class="info__text">
												-{{usingPoint}}P
											</div>
										</div>
									</li>
									<li v-if="(saveArmyPrice + saveBenefitPrice) > 0">
										<h4 class="info__title">
											제휴할인
										</h4>
										<div class="info__wrap">
											<div class="info__text">
												-{{(saveArmyPrice + saveBenefitPrice).toLocaleString("en-US")}}원
											</div>
										</div>
									</li>
								</ul>
							</template>
							<div class="guest-button" v-else>
								<button type="button" class="btn btn-change" @click="handleLogin">
									로그인하고 할인 혜택 받으세요!<!--재번역 체크-->
								</button>
							</div>
							<!-- 총 결제 금액 -->
							<ul class="info">
								<li class="point">
									<h4 class="info__title">
										총 결제금액
									</h4>
									<div class="info__wrap">
										<div class="info__text">
											<strong class="red">{{lastPrice.toLocaleString("en-US")}}</strong>원
										</div>
									</div>
								</li>
							</ul>
						</div>
					</article>
					<!-- 결제 수단 선택 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<!-- <h3 class="booking-box__title">결제수단 선택</h3> -->
							<!-- 결제 수단 목록 -->
							<div class="checkout" v-if="urlhost !== 'www.hellostay.co.kr'">
								<div class="btns">
									<!-- [D] 혜택 있을 경우 btn-checkout--benefit 클래스 추가 -->
									<!-- [D] 체크 되었을 경우 active 클래스 추가 -->
									<button v-for="payType in payTypes" :key="payType.type" type="button" class="btn-checkout" :class="{'btn-checkout--benefit': payType.benefit !== undefined, active: paytype === payType.type}" @click="handlePay(payType)">
										<img v-if="payImages[payType.type] !== undefined" :src="payImages[payType.type]" :alt="payType.name" :style="payType.style">
										<template v-else>{{payType.name}}</template>
									</button>
									<!-- <button type="button"></button> -->
								</div>
							</div>
							<!-- 혜택 안내 -->
							<div class="benefit" v-if="pay?.benefit !== undefined">
								<div class="benefit__title">
									<span class="benefit__badge">혜택</span>
									<span class="benefit__logo">
										<img v-if="payImages[pay.type] !== undefined" :src="payImages[pay.type]" :alt="pay.name">
										<template v-else>{{pay.name}}</template>
										<!-- <img src="@/assets/images/icon_payco.png" alt="payco">
										신용카드 -->
									</span>
								</div>
								<div class="benefit__wrap">
									<div class="benefit__name">
										{{pay.name}} 결제 혜택
									</div>
									<div class="benefit__info">
										{{pay.benefit.BENEFITS_DESC}}
									</div>
									<div class="benefit__date">
										{{dayjs(pay.benefit.SDATE).format("YYYY.MM.DD")}}~ {{dayjs(pay.benefit.EDATE).format("YYYY.MM.DD")}}까지
									</div>
								</div>
							</div>
							<!-- 현금영수증 -->
							<div class="receipt" v-if="pay !== undefined && pay.type === '05'">
								<div class="receipt__items flex">
									<div class="receipt__item">
										<label>
											<div class="radio">
												<input type="radio" class="rad blind" name="isDormant" value="01" v-model="receipt">
												<span class="radio__bg"></span>
												<span>소득공제</span>
											</div>
										</label>
									</div>
									<div class="receipt__item">
										<label>
											<div class="radio">
												<input type="radio" class="rad blind" name="isDormant" value="02" v-model="receipt">
												<span class="radio__bg"></span>
												<span>지출증빙</span>
											</div>
										</label>
									</div>
									<div class="receipt__item">
										<label>
											<div class="radio">
												<input type="radio" class="rad blind" name="isDormant" value="03" v-model="receipt">
												<span class="radio__bg"></span>
												<span>미발행</span>
											</div>
										</label>
									</div>
								</div>
								<div class="receipt__notice" v-if="receipt === '03'">
									{{t('10500')}}
								</div>
								<div class="receipt__wrap" v-if="receipt !== '03'">
									<div class="slt" v-if="receipt === '01'">
										<input type="text" class="ipt-frm ipt-slt" :value="receiptType" readonly @focus="onFocusReceiptType(true)">
										<div class="frms__btns">
											<button type="button" class="btn-frmreset"><i class="icon icon-down"></i>
											</button>
										</div><!-- [D] 선택시 input에 입력되고, icon은 제거 -->
										<ul class="slt__list" :class="{'slt__list-show': isSelectReceiptType}">
											<li @click="onClickReceiptType('휴대폰 번호')">{{t('10037')}}</li>
											<li @click="onClickReceiptType('현금영수증 카드 번호')">{{t('10477')}}</li>
										</ul>
										<input v-if="receiptType === '휴대폰 번호'" type="tel" class="ipt-frm ipt-slt" :placeholder="t('10828')" v-model="receiptPhoneNumber" @keyup="receiptCheckNumber" maxlength="13">
										<input v-else type="tel" class="ipt-frm ipt-slt" v-model="cardNumber"/>
									</div>
									<div class="slt" v-else>
										<input type="text" pattern="[0-9]*" inputmode="numeric" class="ipt-frm ipt-slt" :value="companyNumber" @input="event => companyNumber = event.target.value" />
									</div>
									<div class="receipt__notice">
										{{t('10478')}}
									</div>
									<div class="checkbox grey1">
										<label>
											<input type="checkbox" class="chk blind" v-model="receiptAgree">
											<span class="checkbox__bg"></span> {{t('10479')}}

										</label>
									</div>
								</div>
							</div>
							<!-- 다음 결제 수단으로 사용 여부 -->
							<div class="next" v-if="isLogin">
								<div class="checkbox grey1">
								<label>
									<input type="checkbox" class="chk blind" v-model="usingNext">
									<span class="checkbox__bg"></span> {{t('10480')}}
								</label>
								</div> 
							</div>
						</div>
					</article>
					<!-- 스탬프 -->
					<article class="booking-box" v-if="isLogin">
						<div class="box box--reverse">
							<h3 class="booking-box__title">스탬프</h3>
							<div class="stamp-desc">숙박 퇴실 24시간 후 스탬프 1회 발급</div>
							<div class="stamp-desc purple">스탬프 5회 발급시 5,000P 지급!</div>
							<div class="stamp__list">
								<span class="stamp__item" :class="{'active': stamp >= index}" v-for="index in 4" :key="index">
									<!-- [D] 스탬프 찍었을때 아이콘 표시 -->
									<i class="icon" :class="urlhost !== 'www.hellostay.co.kr' ? 'icon-H_SALAD' : 'icon-H_stay'" v-if="stamp >= index"></i>
									<template v-else>
										{{index}}회
									</template>
								</span>
								<span class="stamp__item">
									5000P
								</span>
							</div>
						</div>
					</article>
					<!-- 안내와 약관동의 -->
					<article class="booking-box booking-box--last">
						<div class="box box--reverse">
							<!-- 공지 -->
							<div class="notice__wrap">
								<div class="notice__item">
									<h4 class="notice__title">
										<i class="icon icon-warning--point"></i>
										{{t('10481')}}
									</h4>
									<p class="description">
										{{t('10482')}}
									</p>
								</div>
								<div class="notice__item">
									<h4 class="notice__title">
										<i class="icon icon-warning--point"></i>
										{{t('10483')}}
									</h4>
									<p class="description">
										{{t('10484')}}
									</p>
								</div>
							</div>
							<!-- 동의 -->
							<ul class="agrees">
								<li>
									<div class="checkbox">
										<label>
											<input type="checkbox" class="chk blind" :checked="allChecked" @change="allCheck($event)">
											<span class="checkbox__bg"></span>
											<span class="agree__title">{{t('10485')}}</span>
										</label>
									</div>
								</li>
								<li>
									<div class="checkbox">
										<label>
											<input type="checkbox" class="chk blind" value="rule" v-model="checkedList">
											<span class="checkbox__bg"></span>
											<span class="agree__title">{{t('10486')}}</span>
										</label>
									</div>
									<button type="button">
										<i class="icon icon-right--light" @click="handleOpenTerm('room')">보기</i>
									</button>
								</li>
								<li>
									<div class="checkbox">
										<label>
											<input type="checkbox" class="chk blind" value="cancel" v-model="checkedList">
											<span class="checkbox__bg"></span>
											<span class="agree__title">{{t('10487')}}</span>
										</label>
									</div>
									<button type="button">
										<i class="icon icon-right--light" @click="handleOpenTerm('cancel')">보기</i>
									</button>
								</li>
								<li>
									<div class="checkbox">
										<label>
											<input type="checkbox" class="chk blind" value="privacy3" v-model="checkedList">
											<span class="checkbox__bg"></span>
											<span class="agree__title">{{t('10488')}}</span>
										</label>
									</div>
									<button type="button">
										<i class="icon icon-right--light" @click="handleOpenTerm('third')">보기</i>
									</button>
								</li>
								<li>
									<div class="checkbox">
										<label>
											<input type="checkbox" class="chk blind" value="privacy" v-model="checkedList">
											<span class="checkbox__bg"></span>
											<span class="agree__title">{{t('10734')}}</span>
										</label>
									</div>
									<button type="button">
										<i class="icon icon-right--light" @click="handleOpenTerm('agree')">보기</i>
									</button>
								</li>
							</ul>
							<p class="description" v-html="t('10489')">
							</p>
							<div class="booking-box__button">
								<div class="btns">
								<button type="button" class="btn btn-confirm" @click="handleSubmit">
										{{lastPrice.toLocaleString("en-US")}}{{t('10490')}}
									</button>
								</div>
							</div>
						</div>
					</article>
				</section>
			</div>
		</div>
	</div>
	<Coupon 
		v-if="openCoupon" 
		:handleClose="handleCloseCoupon" 
		:selectedCouponByOrderId="selectedCouponByOrderId" 
		:selectedCoupons="selectedCouponByOrderId[selectCouponOrderId] ?? []"
		:selectCouponHotelNo="selectCouponHotelNo"  
		:handleSelectCoupons="handleSelectCoupons"
		:mrm0206="mrm0206"
		:order="orderRooms[selectCouponOrderId]"
		:orderId="selectCouponOrderId"
	/>
	<CouponStatus 
		v-if="openCouponStatus"
		:handleClose="handleCloseCouponStatus"
		:selectedCoupons="selectedCouponByOrderId"
		:orders="orderRooms"
	/>
	<Way v-if="openWay" :handleClose="handleCloseWay" :orderRooms="orderRooms" :handleWays="handleWays" :ways="ways" />
	<TermsRooms v-if="openTerm === 'room'" :handleClose="handleCloseTerm"/>
	<TermsCancel v-if="openTerm === 'cancel'" :handleClose="handleCloseTerm" />
	<TermsAgree v-if="openTerm === 'agree'" :handleClose="handleCloseTerm" />
	<TermsThird v-if="openTerm === 'third'" :handleClose="handleCloseTerm" />
	<CancelModal v-if="openPay03CancelModal" :handleClose="handleCloseCancelModal"  />
	<ErrorModal v-if="openPay03ErrorModal" :handleClose="handleCloseErrorModal" />
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<Army v-if="openArmy" :handleClose="handleCloseArmy" :handleClick="handleClickArmy"/>
	<article class="layer" style="display:block;background-color:#fff" v-show="openPayIframe">
		<iframe 
			name="payIframe" 
			style="height: 100%; width: 100%"
			sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation"/>
	</article>
	<TheNavigation></TheNavigation>
</template>

<script>
import { computed, ref } from '@vue/reactivity';
import { useStore } from 'vuex'
import { onBeforeUnmount, onMounted, watch, watchEffect } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
import usePhoneValidate from '@/composables/usePhoneValidate';
import { checkPhoneNumber } from '@/utils/formatter';

import Coupon from '@/components/layers/Coupon';
import CouponStatus from "@/components/layers/Couponstatus";
import Way from "@/components/layers/Way";
import TermsRooms from "@/components/layers/Terms_rooms";
import TermsCancel from "@/components/layers/Terms_cancel";
import TermsAgree from "@/components/layers/Terms_agree";
import TermsThird from "@/components/layers/Terms_third";
import CancelModal from "@/components/layers/Message_cancel";
import ErrorModal from "@/components/layers/Message_error";
import ErrorAlert from "@/components/layers/Message_alert";
import Army from '@/components/layers/Army';

import api from "@/urls/pay01";
import mmaaApi from "@/urls/mmaa";
import mmy01 from "@/urls/mmy01";
import mrm02 from "@/urls/mrm02";

import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
dayjs.locale("ko");

export default {
	name: "OrderApply",
	components: {
		Coupon,
		Way,
		TermsRooms,
		TermsCancel,
		TermsAgree,
		TermsThird,
		CouponStatus,
		CancelModal,
		ErrorModal,
		ErrorAlert,
		Army
	},
	setup() {
		const payTypes = [{
			name: "페이코",
			type: "12",
			code: "0000000000010",
		},{
			name: "신용/체크카드",
			type: "03",
			code: "1000000000000",
		},{
			name: "네이버페이",
			type: "10",
			code: "0000000000001",
			style: "height:20px"
		},{
			name: "카카오페이",
			type: "11",
			code: "0000000010000",
			style: "height:18px"
		},{
			name: "SSG",
			type: "13",
			code: "0000000001000",
		},{
			name: "간편 계좌이체",
			type: "05",
			code: "0010000000000",
		}
		// ,{
		// 	name: "L.pay",
		// 	type: "14",
		// 	code: "0000000000100",
		// }
		]
		const payImages = {
			"12": require("@/assets/images/icon_payco.png"),
			"10": require("@/assets/images/icon_naverpay.png"),
			"11": require("@/assets/images/icon_kakaopay.png"),
			"13": require("@/assets/images/icon_ssg.png"),
			// "14": require("@/assets/images/icon_lpay.png"),
		}
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const { t }= useI18n() //번역필수 모듈
		const name = ref("");
		const lang = ref("");
		const alert_msg = ref("");
		const guestName = ref("");
		const guestPhoneNumber = ref("");
		const receiptPhoneNumber = ref("");
		const sameGuest = ref(false);
		const totalPoint = ref(0);
		const usingPoint = ref("0");
		const pay = ref();
		const paytype = ref("03");
		const receipt = ref("01");
		const receiptType = ref("휴대폰 번호");
		const isSelectReceiptType = ref(false);
		const cardNumber = ref("");
		const companyNumber = ref("");
		const openTerm = ref(null);
		const apiurl = ref('');
		const usingNext = ref(false);
		const receiptAgree = ref(false);

		const openWay = ref(false);
		const ways = ref([]);

		const selectedCouponByOrderId = ref({});

		const verifyCode = ref('');
		const phoneNumber = ref("");
		const isPhoneNumber = ref(false);
		const isSended = ref(false);
		const isFailed = ref(false); 
		const isVerified = ref(false);
		const changeVerify = ref(false);
		const saveRequestData = ref();

		const sgubun = ref();
		const alert_return = ref(false);
		const checkedList = ref([]);
		const openErrorAlert = ref(false)
		const openPayIframe = ref(false);
		const selectCouponHotelNo = ref(undefined);
		const selectCouponOrderId = ref(undefined);
		const openCoupon = ref(false);
		const openCouponStatus = ref(false);

		const orders = computed(() => store.state.order.orderItems);
		const rooms = computed(() => store.state.mrm02.room);
		const room_type = rooms.value[orders.value[0].hotelNo]?.[orders.value[0].prodNo].room_type;
		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const mmy0101 = computed(() => store.state.mmy01.mmy0101);
		const urlhost = ref();
		const orderRooms = computed(() => orders.value.reduce((acc, cur, index) => {
			const room = rooms.value[cur.hotelNo]?.[cur.prodNo];
			if(room) {
				const days = dayjs(cur.edate).diff(cur.sdate, "day");
				const price = cur.isDayuse ? room.dayuse_amt : room.sales_amt;
				////console.log(price);
				const coupons = selectedCouponByOrderId.value[index] ?? [];
				const dcPrice = coupons.reduce((acc, cur) => acc + (cur.CP_AMT !== 0 ? cur.CP_AMT : Math.round(price * cur.CP_DCRATE / 100)), 0);
				const dcCds = coupons.map(cur => cur.EV_NO);
				const dcNos = coupons.map(cur => cur.CP_NO);
				const dcAmts = coupons.map(cur => cur.CP_AMT !== 0 ? cur.CP_AMT : Math.round(price * cur.CP_DCRATE / 100));
				const dcTypes = Array(coupons.length).fill("04");
				return [...acc, {
					...cur,
					room,
					id: index,
					checkin: dayjs(cur.sdate).format("YYYY.MM.DD(dd)") + " " + (cur.isDayuse ? cur.sdayuse : room.stay_stime),
					checkout: cur.isDayuse ? cur.edayuse : dayjs(cur.edate).format("YYYY.MM.DD(dd)") + " " + room.stay_etime,
					days,
					price,
					dcPrice,
					dcTypes: dcTypes,
					dcCds: dcCds,
					dcNos: dcNos,
					dcAmts: dcAmts,
					couponsLength: coupons.length,
					isCoupon: store.state.mrm02.mrm0206[cur.hotelNo]?.length > 0 ?? false,
				}];
			}
			else {
				return acc;
			}
		}, []));

		const totalPrice = computed(() => orderRooms.value.reduce((acc, cur) => {
			if(cur.room) {
				const price = cur.isDayuse ? cur.room.dayuse_amt : cur.room.sales_amt;
				return acc + price;
			}else {
				return acc;
			}
		}, 0));

		const saveArmyPrice = ref(0);
		const saveBenefitPrice = ref(0);

		const lastPrice = computed(() => {
			const point = Number(usingPoint.value.replace(/[^0-9]/g, ""));
			const couponPrice = orderRooms.value.reduce((acc, cur) => acc + cur.dcPrice, 0);
			const benefit = pay.value?.benefit;
			let armyPercent = 0;
			let total = totalPrice.value - couponPrice - point;
			
			if(userData.value?.mmaa_yn === "Y") {
				armyPercent = 10;
			}

			const armyPrice = Math.round(total * armyPercent / 100);
			total = total - armyPrice;

			let benefitPrice = 0;
			if(benefit) {
				benefitPrice = benefit.BENEFITS_AMT !== 0 ? benefit.BENEFITS_AMT : Math.round(total * benefit.BENEFITS_RATE / 100);
			}
			total = total - benefitPrice;
			
			saveArmyPrice.value = armyPrice;
			saveBenefitPrice.value = benefitPrice;
			if(total < 0) {
				total = 0;
			}
			return total;
		});

		const payTypesWithBenefit = computed(() => payTypes.map(payType => ({
			...payType,
			benefit: store.state.mcs01.mcs0104.find(benefit => benefit.PAY_TYPE === payType.type),
		})));	

		watchEffect(() => totalPoint.value = mmy0101.value?.[0]?.mem_point ?? 0);
		watchEffect(() => receiptPhoneNumber.value = checkPhoneNumber(userData.value?.hp_no ?? "")[0]);
		watchEffect(() => {
			if(pay.value === undefined){
				pay.value = payTypesWithBenefit.value.find(payType => payType.type === userData.value?.liking_mop)
			}
		})

		watch(() => [store.state.mrm02.mrm0206, orders.value], ([watchedMrm0206, orders]) => {
			Object.keys(watchedMrm0206).forEach(hotelNo => {
				if(watchedMrm0206[hotelNo].length > 0) {
					const hotelOrders = orders.map((order, index) => ({...order, id: index})).filter(order => order.hotelNo === hotelNo);
					hotelOrders.forEach(order => {
						const coupons = watchedMrm0206[hotelNo].filter(coupon => coupon.USE_YN === "Y" && (
							order.isDayuse ? coupon.DAYUSE_YN === "Y" : coupon.STAY_YN === "Y"
						) && coupon.AUTO_APPLY === "Y");
						if (coupons.length > 0) {
							////console.log(totalPrice.value);
							////console.log(coupons);
							coupons.sort((a, b) => b.CP_AMT - a.CP_AMT);
							const coupon = coupons.find(coupon => {
								const isUsedCoupon = Object.keys(selectedCouponByOrderId.value).find(orderId => {
									const sameCoupon = selectedCouponByOrderId.value[orderId].find(selectedCoupon => {
										//console.log(selectedCoupon.hotelNo, order.hotelNo, selectedCoupon.CP_NO, coupon.CP_NO)
										return selectedCoupon.CP_NO === coupon.CP_NO
									});
									return sameCoupon !== undefined;
								});
								return isUsedCoupon === undefined;
							})
							if(coupon === undefined) {
								return;
							}
							selectedCouponByOrderId.value[order.id] = [{...coupon, hotelNo: order.hotelNo}];
						}
					})
				}
			});
		}, {deep: true})

		// watch(() => orderRooms.value, (rooms) => {
		// 	rooms.forEach((order) => {
		// 		if(order.price - order.dcPrice < 0) {
		// 			selectedCouponByOrderId.value[order.id] = [];
		// 		}
		// 	})
		// }, {deep: true});

		const syncLocalStorage = () => {
			const orderState = localStorage.getItem("orderState");
			if(orderState) {
				const orderStateObj = JSON.parse(orderState);
				name.value = orderStateObj.name;
				guestName.value = orderStateObj.guestName;
				guestPhoneNumber.value = orderStateObj.guestPhoneNumber;
				receiptPhoneNumber.value = orderStateObj.receiptPhoneNumber;
				sameGuest.value = orderStateObj.sameGuest;
				totalPoint.value = orderStateObj.totalPoint;
				usingPoint.value = orderStateObj.usingPoint;
				pay.value = orderStateObj.pay;
				receipt.value = orderStateObj.receipt;
				receiptType.value = orderStateObj.receiptType;
				isSelectReceiptType.value = orderStateObj.isSelectReceiptType;
				cardNumber.value = orderStateObj.cardNumber;
				companyNumber.value = orderStateObj.companyNumber;
				openTerm.value = orderStateObj.openTerm;
				usingNext.value = orderStateObj.usingNext;
				receiptAgree.value = orderStateObj.receiptAgree;
				openWay.value = orderStateObj.openWay;
				ways.value = orderStateObj.ways;
				selectedCouponByOrderId.value = orderStateObj.selectedCouponByOrderId;
				verifyCode.value = orderStateObj.verifyCode;
				phoneNumber.value = orderStateObj.phoneNumber;
				isPhoneNumber.value = orderStateObj.isPhoneNumber;
				isSended.value = orderStateObj.isSended;
				isFailed.value = orderStateObj.isFailed; 
				isVerified.value = orderStateObj.isVerified;
				changeVerify.value = orderStateObj.changeVerify;
				checkedList.value = orderStateObj.checkedList;
				openPayIframe.value = orderStateObj.openPayIframe;
				selectCouponHotelNo.value = orderStateObj.selectCouponHotelNo;
				selectCouponOrderId.value = orderStateObj.selectCouponOrderId;
				openCoupon.value = orderStateObj.openCoupon;
				openCouponStatus.value = orderStateObj.openCouponStatus;
				store.commit("order/SET_ORDER_ITEMS", { orderItems: orderStateObj.orders});
				localStorage.removeItem("orderState");
			}
		};

		const setLocalStorage = (sgubun) => {
			localStorage.setItem("orderState", JSON.stringify({
				name: name.value,
				guestName: guestName.value,
				guestPhoneNumber: guestPhoneNumber.value,
				receiptPhoneNumber: receiptPhoneNumber.value,
				sameGuest: sameGuest.value,
				totalPoint: totalPoint.value,
				sgubun: sgubun,
				usingPoint: usingPoint.value,
				pay: pay.value,
				receipt: receipt.value,
				receiptType: receiptType.value,
				isSelectReceiptType: isSelectReceiptType.value,
				cardNumber: cardNumber.value,
				companyNumber: companyNumber.value,
				openTerm: openTerm.value,
				usingNext: usingNext.value,
				receiptAgree: receiptAgree.value,
				openWay: openWay.value,
				ways: ways.value,
				selectedCouponByOrderId: selectedCouponByOrderId.value,
				verifyCode: verifyCode.value,
				phoneNumber: phoneNumber.value,
				isPhoneNumber: isPhoneNumber.value,
				isSended: isSended.value,
				isFailed: isFailed.value,
				isVerified: isVerified.value,
				changeVerify: changeVerify.value,
				checkedList: checkedList.value,
				openPayIframe: openPayIframe.value,
				selectCouponHotelNo: selectCouponHotelNo.value,
				selectCouponOrderId: selectCouponOrderId.value,
				openCoupon: openCoupon.value,
				openCouponStatus: openCouponStatus.value,
				orders: orders.value,
			}));
		}

		const initWay = async () => {
			const tmpWays = await Promise.all(orders.value.map(async (order) => {
				const data = await mrm02.getMrm02011({proc_cd: "01", hotel_no: order.hotelNo, prod_no: order.prodNo, sdate: order.sdate});
				if(data.data.Res_data.length > 0) {
					return {...order, parking_yn: data.data.Res_data[0].PARKING_YN};
				} else {
					return {...order, parking_yn: 'N'};
				}
			}));
			ways.value = tmpWays.filter(way => way.parking_yn !== "Y").map(order => ( {
				hotel_no: order.hotelNo,
				visit_mean: "도보방문",
				unavailed: order.parking_yn === "X",
			}))
		}
		
		onMounted(() => {
			apiurl.value = "https://admin.hellostay.co.kr";
			//apiurl.value = "http://127.0.0.1:8000";
			const orderState = localStorage.getItem("orderState");
			if (orderState) {
				syncLocalStorage();
			}
			else if(orders.value.length === 0){
				router.replace("/");
			} else {
				//console.log("??");
			  initWay();
			}
			if(route.query.imp_uid) {
				mmaaApi.postMmaa1({MMAA_NM: name.value, MMAA_CI: route.query.imp_uid,mem_id: userData.mem_id,gubun: sgubun})
				.then((data) => {
					if(data.data.Res_cd === "0000") {
						store.commit("SET_USERDATA", {userData: {
							...userData.value,
							mmaa_yn: "Y",
						}});
					}
				}).catch((e) => {
					console.log(e);
				});
			}
			
		})

		watchEffect(() => {
			orders.value.forEach(order => {
				store.dispatch("mrm02/fetchRoomDetailRoom", { 
					hotelNo: order.hotelNo, 
					prodNo: order.prodNo, 
					arrDate: order.sdate,
					depDate: order.isDayuse ? order.sdate : order.edate,
				});
			})
			//console.log(room_type)
			store.dispatch("mcs01/fetchMcs01", { proc_cd: "09" });
			store.dispatch("mcs01/fetchMcs0104");
			if(isLogin.value) {
				store.dispatch("mmy01/fetchMmy0101", {
					proc_cd: "01",
					mem_id: userData.value.mem_token
				});
				orders.value
					.reduce((acc, cur) => {
						if(!acc.includes(cur.hotelNo)) {
							return [...acc, cur.hotelNo];
						}
						return acc;
					}, [])
					.forEach(hotelNo => {
						store.dispatch("mrm02/fetchMrm0206", {
							hotelNo: hotelNo,
							room_type: room_type,
							mem_id: userData.value.mem_token
						})
					})
			}
		})

		const pointInput = () => {
			const number = Number(usingPoint.value.replace(/[^0-9]/g, ""));
			const couponPrice = orderRooms.value.reduce((acc, cur) => acc + cur.dcPrice, 0);
			const leftPrice = totalPrice.value - couponPrice;
			if(number < 0) {
				usingPoint.value = "0";
			}
			else {
				//console.log(leftPrice, totalPoint.value, number)
				usingPoint.value = Math.min(leftPrice, totalPoint.value, number).toLocaleString("en-US");
			}
		}

		const {
			phoneValidateTime,
			onClickSendPhoneNumber,
			serverPhoneValidateNumber
		} = usePhoneValidate("04");

		const checkNumber = () => {
			const [number, isValid] = checkPhoneNumber(phoneNumber.value);
			phoneNumber.value = number;
			isPhoneNumber.value = isValid;
		}

		const guestCheckNumber = () => {
			const [number] = checkPhoneNumber(guestPhoneNumber.value);
			guestPhoneNumber.value = number;
		}

		const receiptCheckNumber = () => {
			const [number] = checkPhoneNumber(receiptPhoneNumber.value);
			receiptPhoneNumber.value = number;
		}

		const verify = () => {
			//console.log(verifyCode.value, typeof verifyCode.value);
			//console.log(serverPhoneValidateNumber.value, typeof serverPhoneValidateNumber.value);
			if(serverPhoneValidateNumber.value !== verifyCode.value.toString()) {

				//alert_msg.value = "인증 번호가 일치하지 않습니다. 다시 확인해주세요."
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "인증 번호가 일치하지 않습니다. 다시 확인해주세요."});
				openErrorAlert.value = true;
				return;
			}
			if(phoneValidateTime.value === 0){
				//alert_msg.value = "입력시간을 초과하였습니다. 재전송 버튼을 눌러주세요."
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "입력시간을 초과하였습니다. 재전송 버튼을 눌러주세요."});
				openErrorAlert.value = true;
				return;
			}
			isVerified.value = true;

		}

		const reset = () => {
			isPhoneNumber.value = false;
			phoneNumber.value = "";
		}

		const onClickReceiptType= (item)=> {
			receiptType.value = item;
			isSelectReceiptType.value = false;
		}

		const onFocusReceiptType = (isFocus) =>{
			isSelectReceiptType.value = isFocus;
		}
		
		const value = ['rule', 'cancel', 'privacy3', 'privacy',];
		

		const allChecked = computed(
			() => (value
				.filter(checked => checkedList.value.includes(checked)))
			.length === 4
		);

		const allCheck = (event) => {
			if (event.target.checked) {
				checkedList.value = value;
			} else {
				checkedList.value = [];
			}
		};
		
		const handleLogin = () => {
			setLocalStorage();
			router.push("/layers/login");
		}

		const handleSubmit = async () => {
			if(name.value.length === 0){
				//alert("예약자명을 입력해주세요.");
				store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "예약자명을 입력해주세요."});
					openErrorAlert.value = true;
				return;
			}
			if(!isLogin.value && !isVerified.value) {
				//alert("휴대폰 번호를 인증해주세요.");
				store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "휴대폰 번호를 인증해주세요."});
					openErrorAlert.value = true;
				return;
			}
			if(!sameGuest.value) {
				if(guestName.value.length === 0){
					//alert("방문자명을 입력해주세요.");
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "방문자명을 입력해주세요."});
					openErrorAlert.value = true;
					return;
				}
				if(guestPhoneNumber.value.length === 0){
					//alert("방문자 휴대폰 번호를 입력해주세요.");
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "방문자 휴대폰 번호를 입력해주세요."});
					openErrorAlert.value = true;
					return;
				}
			}
			if(ways.value.length === 0) {
				//alert("숙소 방문 수단을 선택해주세요.");
				store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "숙소 방문 수단을 선택해주세요."});
					openErrorAlert.value = true;
				return;
			}
			if(pay.value === undefined) {
				//alert("결제수단을 선택해주세요.");
				store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "결제수단을 선택해주세요."});
					openErrorAlert.value = true;
				return;
			}
			if(pay.value.type === "05" && ((receipt.value === "01" || receipt.value === "02") && receiptAgree.value === false)) {
				//alert("현금영수증 정보 수집에 동의해주세요.");
				store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "현금영수증 정보 수집에 동의해주세요."});
					openErrorAlert.value = true;
				return;
			}
			if(!allChecked.value) {
				//alert("이용약관에 모두 동의해주세요.");
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "이용약관에 모두 동의해주세요."});
				openErrorAlert.value = true;
				return;
			}
			if(usingNext.value){
				await mmy01.getMmy01011({proc_cd: "01", mem_id: userData.value.mem_token, data: pay.value.type});
				store.commit("SET_USERDATA", {userData: {
					...userData.value,
					liking_mop: pay.value.type,
				}});
			}
			//console.log(orderRooms.value);

			let dc_types = [];
			let dc_cds = [];
			let dc_nos = [];
			let dc_amts = [];
			orderRooms.value.forEach(item => {
				dc_types.push(...item.dcTypes);
				dc_cds.push(...item.dcCds);
				dc_nos.push(...item.dcNos);
				dc_amts.push(...item.dcAmts);
			});
			const usingPointNumber = Number(usingPoint.value.replace(/[^0-9]/g, ""));
			if(usingPointNumber > 0) {
				dc_types.push("21");
				dc_cds.push("0");
				dc_nos.push("0");
				dc_amts.push(usingPointNumber);
			}

			const isArmy = userData.value?.mmaa_yn === "Y";
			if(isArmy) {
				dc_types.push("23");
				dc_cds.push("0");
				dc_nos.push("0");
				dc_amts.push(saveArmyPrice.value);
			}

			const benefit = pay.value?.benefit;
			if(benefit) {
				dc_types.push("88");
				dc_cds.push("0");
				dc_nos.push("0");
				dc_amts.push(saveBenefitPrice.value);
			}

			const requestData = {
				issue_nm: name.value, 
				issue_nm2: "",
				issue_gender: "",
				issue_tel: "",
				issue_hp: (isVerified.value ? phoneNumber.value : checkPhoneNumber(userData.value.hp_no)[0]).replace(/[^0-9]/g, ""), 
				issue_email: isLogin.value ? userData.value.mem_id : "",
				guest_nm: sameGuest.value ? name.value : guestName.value,
				guest_nm2: "",
				guest_gender: "",
				guest_tel: "",
				guest_hp: (sameGuest.value ? (isVerified.value ? phoneNumber.value : checkPhoneNumber(userData.value.hp_no)[0]) : guestPhoneNumber.value).replace(/[^0-9]/g, ""), 
				guest_email: "",
				country_cd: "KOR",
				special_reqst: "",
				mem_id: isLogin.value ? userData.value.mem_id : "", 
				membership_no: "",
				info_sms_yn: "",
				source: "",
				office_nos: "",
				hotel_nos: orderRooms.value.map(order => order.hotelNo).join(","), 
				prod_nos: orderRooms.value.map(order => order.prodNo).join(","), 
				prod_nms: orderRooms.value.map(order => order.room.prod_nm).join(","), 
				prod_types: orderRooms.value.map(order => order.isDayuse ? "D" : "S").join(","),
				prod_cnts: orderRooms.value.map(() => "1").join(","),
				checkin_dates: orderRooms.value.map(order => order.sdate).join(","), 
				checkin_times: orderRooms.value.map(order => order.isDayuse ? order.sdayuse : order.room.stay_stime).join(","), 
				checkout_dates: orderRooms.value.map(order => order.isDayuse ? order.sdate : order.edate).join(","), 
				checkout_times: orderRooms.value.map(order => order.isDayuse ? order.edayuse : order.room.stay_etime).join(","),
				adult_cnts: orderRooms.value.map(order => order.adult_cnt ?? 0).join(","), 
				child_cnts: orderRooms.value.map(order => order.child_cnt ?? 0).join(","), 
				baby_cnts: orderRooms.value.map(() => "0").join(","),
				visit_means: ways.value.map(way => way.visit_mean === "차량방문" ? "02" : "01").join(","), 
				car_nos: "",
				room_types: orderRooms.value.map(order => order.room.room_type).join(","), 
				breakfast_cnts: "",
				prod_amts: orderRooms.value.map(order => order.isDayuse ? order.room.dayuse_amt : order.room.sales_amt).join(","), 
				ext_amts:  orderRooms.value.map(() => "0").join(","),
				tot_amts: orderRooms.value.map(order => order.isDayuse ? order.room.dayuse_amt : order.room.sales_amt).join(","),
				ymd_prods: orderRooms.value.map(order => order.prodNo).join(","),
				ymd_ymds: orderRooms.value.map(order => order.sdate).join(","),
				ymd_prodamts: orderRooms.value.map(order => order.isDayuse ? order.room.dayuse_amt : order.room.sales_amt).join(","),
				ymd_extamts: orderRooms.value.map(() => "0").join(","),
				ymd_totamts: orderRooms.value.map(order => order.isDayuse ? order.room.dayuse_amt : order.room.sales_amt).join(","),
				ext_nos: "",
				ext_ymds: "",
				ext_cnts: "",
				ext_totamts: "",
				dcalliance_cd: isArmy ? "01" : "", // 01군인공제회
				dc_point: usingPointNumber,
				dc_coupon: orderRooms.value.reduce((acc, cur) => acc + cur.dcPrice, 0),
				dc_alliance: isArmy ? saveArmyPrice.value : "0", // 제휴할인금액
				dc_other: saveBenefitPrice.value,
				dc_tot: usingPointNumber + Number(orderRooms.value.reduce((acc, cur) => acc + cur.dcPrice, 0)) + saveBenefitPrice.value + saveArmyPrice.value, //다른 혜택 추가되면 + 
				sum_tot_amt: orderRooms.value.reduce((acc, cur) => acc + (cur.isDayuse ? cur.room.dayuse_amt : cur.room.sales_amt), 0),
				dc_types: dc_types.join(","), // code 들어있는 문서 언제?? 21포인트 04쿠폰 23군인공제 88할인
				dc_cds: dc_cds.join(","), // (이벤트번호)
				dc_nos: dc_nos.join(","), // (쿠폰번호 등)
				dc_amts: dc_amts.join(","), // (쿠폰금액)
				dc_remarks: "",
				dc_bookingseqs: orderRooms.value.map((order, index) => order.couponsLength > 0 ? index + 1 : '').join(","),
				pay_type: pay.value.type,
				cr_type: pay.value.type === "05" ? receipt.value : "",
				cr_proof: pay.value.type === "05" ? (receipt.value === "02" ? companyNumber.value : ( receipt.value === "01" ? (receiptType.value === "휴대폰 번호" ? receiptPhoneNumber.value : cardNumber.value ) : "" )) : "",
				van_cd: "NICE",
				insert_id: "USER1",
			};
			requestData["pay_amt"] = requestData["sum_tot_amt"] - requestData["dc_tot"];
			if(requestData["pay_amt"] <= 0) {
				//alert("구매 비용이 0원 이하입니다");
				alert_msg.value = ""
				store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "구매 비용이 0원 이하입니다."});
					openErrorAlert.value = true;
			}
			
			if(alert_return.value === false){
				//alert("이용약관에 모두 동의해주세요.");
				alert_msg.value = "14"
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "예약하시겠습니까?"});
				openErrorAlert.value = true;
			}
			if(alert_return.value === true){
				//console.log(requestData);
				const data = await api.getPay01(requestData);
				if(requestData["pay_amt"] > 0) {
					const pgKey = data.data.Res_data[0]?.PG_KEY;
					if(requestData["van_cd"] === 'KSNET'){
						//console.log("KSNET")
						if(pgKey) {
							openPayIframe.value = true;
							window.open(
								//"https://admin.1H_SALAD.co.kr/INF/INF_KSPAY" +
								apiurl.value + "/INF/KSPAY/kspay_wh_order.html" +
								//"https://pay.1H_SALAD.co.kr/kspay_wh_order.html" +
								"?payMethod=" + pay.value.code +
								"&refNo=" + pgKey + //나중에 수정
								"&pg_key=" + pgKey +
								"&prod_nm=" + requestData.prod_nms +
								"&total_Price=" + requestData["pay_amt"] +
								// "&total_Price=" + 1000 +
								"&issue_nm=" + requestData.issue_nm +
								"&issue_hp=" + requestData.issue_hp , "payIframe");
								saveRequestData.value = {
								...requestData,
								pgKey: pgKey,
								code: pay.value.code,
							};
						}
					}else if(requestData["van_cd"] === 'NICE'){
						//console.log("KSNET")
						if(pgKey) {
							openPayIframe.value = true;
							window.open(
								//"https://admin.1H_SALAD.co.kr/INF/INF_KSPAY" +
								apiurl.value + "/PG/payRequest" +
								//"https://pay.1H_SALAD.co.kr/kspay_wh_order.html" +
								"?payMethod=" + pay.value.code +
								"&merchantID=" + "nicepay00m" + //나중에 수정
								"&merchantKey=" + "EYzu8jGGMfqaDEp76gSckuvnaHHu+bC4opsSN6lHv3b2lurNYkVXrZ7Z1AoqQnXI3eLuaUFyoRNC6FkrzVjceg==" +
								"&GoodsName=" + requestData.prod_nms +
								"&Amt=" + requestData["pay_amt"] +
								// "&total_Price=" + 1000 +
								"&BuyerName=" + requestData.issue_nm +
								"&BuyerTel=" + requestData.issue_hp , "payIframe");
								saveRequestData.value = {
								...requestData,
								pgKey: pgKey,
								code: pay.value.code,
							};
						}
					}
				}else{
					const pgKey = data.data.Res_data[0]?.PG_KEY;
					saveRequestData.value = {
					...requestData,
					pgKey: pgKey,
					code: "NOPG",
					}
					const data1 = await store.dispatch("pay03/fetchPay03", { 
						pg_key: saveRequestData.value.pgKey,
						pg_tid: '',
						pg_resultcode: '',
						pg_resultmsg: '',
						pg_paymethod: saveRequestData.value.code,
						pg_payamt: saveRequestData.value.pay_amt,
						credit_no: "",
						credit_auth_no: '',
						credit_inst: "",
						card_cd: '',
						card_nm: '',
						maeip_cd: '',
						maeip_nm: '',
						login_id: isLogin.value ? userData.value.mem_id : phoneNumber.value,
					});
					const bookingNo = data1.data.Res_data[0].booking_no;
					router.replace("/order/complete/" + bookingNo);
				}
			}
		}
		const sendPay03 = ref(false);
		const openPay03ErrorModal = ref(false);
		const openPay03CancelModal = ref(false);
        const onPayMessage = async (e) =>{
            if (e.origin === apiurl.value){
            	openPayIframe.value = false;
				const iFrame = document.querySelector('iframe');
				if(iFrame){
					const iFrameParent = iFrame.parentElement;
					iFrame.remove();
					iFrameParent.append(iFrame);
				}
            	//console.log(JSON.stringify(e.data));
				if(e.data.res_cd === "9999") {
					window.history.back();
					openPay03ErrorModal.value = true;
					return;
				}else if(e.data.res_cd === "8888") {
					window.history.back();
					openPay03CancelModal.value = true;
					return;
				}
				if(sendPay03.value === true) {
					return;
				}
				sendPay03.value = true;
				try{
				const data = await store.dispatch("pay03/fetchPay03", { 
					pg_key: saveRequestData.value.pgKey,
					pg_tid: e.data.trno,
					pg_resultcode: e.data.result,
					pg_resultmsg: e.data.msg2,
					pg_paymethod: saveRequestData.value.code,
					pg_payamt: e.data.amt,
					credit_no: e.data.credit_no,
					credit_auth_no: e.data.authno,
					credit_inst: e.data.CardQuota,
					card_cd: e.data.isscd,
					card_nm: e.data.msg1,
					maeip_cd: e.data.aqucd,
					maeip_nm: e.data.msg1,
					login_id: isLogin.value ? userData.value.mem_id : phoneNumber.value,
				});
				const bookingNo = data.data.Res_data[0].booking_no;
				router.replace("/order/complete/" + bookingNo);
				}catch(e){
					openPay03ErrorModal.value = true;
				}
			}
        };

        onMounted(() => {
            window.addEventListener("message", onPayMessage);
			pay.value = {code: "1000000000000",name: "신용/체크카드",type: "03"};
			paytype.value = "03";
			
			urlhost.value = store.state.app.urlhost;
			lang.value = store.state.app.lang;
			i18n.global.locale.value = store.state.app.lang;
        });

		onBeforeUnmount(() =>{
			window.removeEventListener('message', onPayMessage);
		});

		const handleOpenCoupon = (hotelNo, orderId) => {
			if(!isLogin.value){
				handleLogin();
				return;
			}
			selectCouponHotelNo.value = hotelNo;
			selectCouponOrderId.value = orderId;
			openCoupon.value = true;
		};

		const handleCloseCoupon = () => {
			openCoupon.value = false;
		}
		const handleClickAlert = () =>{
			alert_return.value = true
			openErrorAlert.value = false;
			handleSubmit()
		}
		const handleSelectCoupons = (coupons) => {
			//console.log(coupons);
			selectedCouponByOrderId.value[selectCouponOrderId.value] = coupons;
			
			pointInput();
		}

		const handleOpenCouponStatus = () => {
			openCouponStatus.value = true;
		}
		
		const handleCloseCouponStatus = () => {
			openCouponStatus.value = false;
		}
		const handleClickArmy = (sgubun) => {
			setLocalStorage(sgubun);
			//const url = process.env.NODE_ENV === "development" ? "http://localhost:8080" : "https://app.1H_SALAD.co.kr/"
			var IMP = window.IMP; // 생략 가능
  			IMP.init("imp40330180"); // 예: imp00000000
			IMP.certification({ // param
				merchant_uid: "ORD20180131-0000011", // 주문 번호
				//m_redirect_url : url + "/order/Apply", // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
				popup : true, // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
				is_iframe: true,
			}, function(rsp) {
                if ( rsp.success ) {
						mmaaApi.getMmaa1({imp_uid: rsp.imp_uid,mem_id: userData.value.mem_id,gubun: sgubun})
							.then((data) => {
								if(data.data.Res_cd === "0000") {
									store.commit("SET_USERDATA", {userData: {
										...userData.value,
										mmaa_yn: "Y",
									}});
									//alert('인증 성공했습니다.')
									store.commit("app/SET_RESMESSAGE", {res_message: null});
									store.commit("app/SET_RESMESSAGE", {res_message: "인증 성공했습니다."});
									openErrorAlert.value = true;
									openArmy.value = false;
								}else{
									//alert('인증 실패했습니다.')
									openArmy.value = false;
									store.commit("app/SET_RESMESSAGE", {res_message: null});
									store.commit("app/SET_RESMESSAGE", {res_message: "인증 실패했습니다."});
									openErrorAlert.value = true;
								}
							})

               	}
            })
		}
		const checkname = () =>{
			if(name.value.length === 0){
				//alert("예약자명을 입력해주세요."
				store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "예약자명을 입력해주세요."});
					openErrorAlert.value = true;
				document.querySelector(".sameGuest").checked = false;
				return;
			}
		}
		const openArmy = ref(false);
		return {
			rooms,
			orders, 
			orderRooms,
			totalPrice,
			name, 
			isLogin,
			userData,
			checkNumber,
			verify,
			phoneNumber,
			onClickSendPhoneNumber: async () => {
				try {
					await onClickSendPhoneNumber(phoneNumber.value);
					isSended.value = true;
				}catch(e) {
					isSended.value = false;
					console.error(e);
				}
			},
			isFailed,
			isSended,
			verifyCode,
			phoneValidateTime,
			reset,
			reSend: () => {
				if(phoneValidateTime.value > 0){
					//alert("인증번호를 재발송 하였습니다. 인증번호가 오지 않는 경우 입력하신 정보를 확인해주세요.");
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "인증번호를 재발송 하였습니다. 인증번호가 오지 않는 경우 입력하신 정보를 확인해주세요."});
					openErrorAlert.value = true;
				}else {
					//alert("인증번호를 재발송 하였습니다.");
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "인증번호를 재발송 하였습니다."});
					openErrorAlert.value = true;
				}
				onClickSendPhoneNumber(phoneNumber.value);
			},
			onChangeVerify: () => {
				changeVerify.value = true;
			},
			changeVerify,
			checkPhoneNumber,
			isVerified,
			sameGuest,
			lastPrice,
			totalPoint: computed(() => totalPoint.value - Number(usingPoint.value.replace(/[^0-9]/g, ""))),
			usingPoint,
			pointInput,
			handleMaxPoint: () => {
				const couponPrice = orderRooms.value.reduce((acc, cur) => acc + cur.dcPrice, 0);
				const leftPrice = totalPrice.value - couponPrice;

				if(totalPoint.value > leftPrice) {
					usingPoint.value = leftPrice.toLocaleString("en-US");
				} else {
					usingPoint.value = totalPoint.value.toLocaleString("en-US");
				}
			},
			resetPoint: () => {
				usingPoint.value = "0";
			},
			handlePay: (select) => {
				//console.log(select);
				pay.value = select;
				paytype.value = select.type;
			
				pointInput();
			},
			pay,
			receipt,
			guestName,
			guestPhoneNumber,
			guestCheckNumber,
			receiptCheckNumber,
			receiptAgree,
			receiptPhoneNumber,
			onClickReceiptType,
			onFocusReceiptType,
			receiptType,
			isSelectReceiptType,
			cardNumber,
			stamp: computed(() => isLogin.value && mmy0101?.value[0]?.stamp_cnt !== undefined
				? mmy0101?.value[0]?.stamp_cnt % 5
				: "-"),
			checkedList,
			allCheck,
			allChecked,
			openPayIframe,
			alert_msg,
			openWay,
			ways,
			handleOpenWay: () => {
				openWay.value = true;
			},
			handleCloseWay: () => {
				openWay.value = false;
			},
			handleWays: (selectWays) => {
				ways.value = selectWays;
			},
			payTypes: payTypesWithBenefit,
			dayjs,
			usingNext,
			handleSubmit,
			companyNumber,
			openTerm,
			handleOpenTerm: (type) => {
				openTerm.value = type;
			},
			handleCloseTerm: () => {
				openTerm.value = null;
			},
			goBack() {
				router.back();
			},
			selectedCouponByOrderId,
			selectCouponHotelNo,
			selectCouponOrderId,
			openCoupon,
			handleOpenCoupon,
			handleCloseCoupon,
			handleSelectCoupons,
			mrm0206: computed(() => store.state.mrm02.mrm0206),
			openCouponStatus,
			handleOpenCouponStatus,
			handleCloseCouponStatus,
			handleLogin,
			payImages,
			openPay03ErrorModal,
			openErrorAlert,
			openPay03CancelModal,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			handleCloseErrorModal: () => {
				openPay03ErrorModal.value = false;
			},
			handleCloseCancelModal: () => {
				openPay03CancelModal.value = false;
			},
			openArmy,
			handleOpenArmy: () => {
				openArmy.value = true;
			},
			handleCloseArmy: () => {
				openArmy.value = false;
			},
			handleClickAlert,
			handleClickArmy,
			sendPay03,
			isPhoneNumber,
			saveArmyPrice,
			t,  //번역필수 모듈
		  i18n,
			paytype,
			alert_return,
			saveBenefitPrice,
			checkname,
			lang,
			room_type,
			urlhost
		}
	},
}
</script>