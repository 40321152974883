<template>
	<div style="display:block" class="layer">
		<div class="layer__dim"></div>
		<div class="layer__full">
			<div class="layer__fullbox">
				<div class="layer__fullheader">
					<nav class="nav">
						<div class="nav__wrap">
							<a @click="handleClose" class="nav__btn">
								<i class="icon icon-close">{{t('10743')}}</i>
							</a>
							<p class="nav__item">
								<span class="nav__text">
									{{t('10744')}}
								</span>
							</p>
							<a class="nav__btn">
							</a>
						</div>
					</nav>
				</div>
				<div class="layer__fullbody">
					<section class="layer-way">
						<div class="box">
							<div class="booking-box">
								<div class="box box--reverse">
									<!-- 예약 숙소 1 -->
									<div class="booking-item" v-for="(order, index) in orderWayRooms" :key="index">
										<div class="booking-item__badge">{{t('10087')}}</div>
										<h3 class="booking-item__title">{{order.room.hotel_nm}}</h3>
										<p class="booking-top__desc">
											<span class="booking-top__badge">{{order.isDayuse ? '대실' : '숙박'}}</span>
											{{order.room.prod_nm}}
										</p>
										<div class="booking-item__date">
											{{order.checkin}} ~ {{order.checkout}}
										</div>
										<div  class="booking-item__select" >
											<!-- [D] 방문 수단 선택 -->
											<div class="btns" v-if="order.parking_yn !== 'X'">
												<label>
													<input type="radio" class="rad blind" :selected="order.parking_yn === 'X'? true : false " :name="`test${index}`" value="도보방문" v-model="selectWays[index].visit_mean">
													<span class="btn btn-way">
														{{t('10745')}}
													</span>
												</label>
												<label v-if="order.parking_yn === 'Y'">
													<input type="radio" class="rad blind" :name="`test${index}`" value="차량방문" v-model="selectWays[index].visit_mean">
													<span class="btn btn-way">
														{{t('10746')}}
													</span>
												</label>
											</div>
											<p v-if="order.parking_yn === 'X'" class="red description">{{t('10463')}}</p>
											<p v-else-if="order.parking_yn !== 'Y'" class="red description">{{t('10747')}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
		<div id="navigation" class="navigation navigation--noborder">
			<!-- [D] 적용 전 -->
			<div class="btns btns--centered">
				<button type="button" class="btn" :class="complete ? 'btn-submit' : 'btn-disabled'" :disabled="!complete" @click="handleSubmit">적용하기</button><!-- 재번역체크 -->
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from '@vue/reactivity'
import api from "@/urls/mrm02";
import { watchEffect } from '@vue/runtime-core';
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 
export default {
	props: ["handleClose", "orderRooms", "handleWays", "ways"],
	setup(props) {
		const selectWays = ref([]);
		const orderWayRooms = ref([])
		const { t }= useI18n() 
		watchEffect(async () => {
			orderWayRooms.value = await Promise.all(props.orderRooms.map(async (order) => {
				const data = await api.getMrm02011({proc_cd: "01", hotel_no: order.hotelNo, prod_no: order.prodNo, sdate: order.sdate});
				if(data.data.Res_data.length > 0) {
					return {...order, parking_yn: data.data.Res_data[0].PARKING_YN};
				} else {
					return {...order, parking_yn: 'N'};
				}
			}))
		})
		watchEffect(() => {
			if(props.ways.length > 0) {
				selectWays.value = [...props.ways];
			} else {
				selectWays.value = orderWayRooms.value.map(order => ({
					hotel_nm: order.room.hotel_nm,
					hotel_no: order.hotelNo,
					visit_mean: order.parkin_yn === "Y" ? "차량방문" : "도보방문",
					unavailed: order.parkin_yn === "X",
				}));
			}
		})

		const handleSubmit = () => {
			props.handleWays(selectWays.value);
			props.handleClose();
		}

		onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})

		return {
			orderWayRooms,
			selectWays,
			complete: selectWays.value.every(way => way !== ""),
			 t,  //번역필수 모듈
		  i18n, 
			handleSubmit
		}
	},
}
</script>