import fetch from "./fetch";

const MRM02_01_URL = () => "/PC/WPC_MRM02_01";
const MRM02_011_URL = () => "/PC/WPC_MRM02_011";
const MRM02_02_URL = () => "/PC/WPC_MRM02_02";
const MRM02_021_URL = () => "/PC/WPC_MRM02_021";
const MRM02_022_URL = () => "/PC/WPC_MRM02_022";
const MRM02_03_URL = () => "/PC/WPC_MRM02_03";
const MRM02_04_URL = () => "/PC/WPC_MRM02_04";
const MRM02_05_URL = () => "/PC/WPC_MRM02_05";
const MRM02_06_URL = () => "/PC/WPC_MRM02_06";

export default {
  getMrm0201: fetch("GET", MRM02_01_URL),
  getMrm02011: fetch("GET", MRM02_011_URL),
  getMrm0202: fetch("GET", MRM02_02_URL),
  getMrm02021: fetch("GET", MRM02_021_URL),
  getMrm02022: fetch("GET", MRM02_022_URL),
  getMrm0203: fetch("GET", MRM02_03_URL),
  getMrm0204: fetch("GET", MRM02_04_URL),
  getMrm0205: fetch("GET", MRM02_05_URL),
  getMrm0206: fetch("GET", MRM02_06_URL),
};
